<template>
  <div id="header-home">
    <div id="header-top">
      <div class="home-localise-cta xs-flex xs-justify-start xs-items-center xs-flex-column home-localise-cta--c5 xs-mnb-30 md-mb-0">
        <div class="mobile-header-top">
          <div class="hidden-lg hidden-md border-radius-bottom flag-box-mobile dropdown-lang" style="z-index: 990;">
            <dropdown menu-right>
              <btn class="dropdown-toggle">
                <img src="../../assets/images/icons/jp.png" class="xs-mr-5" v-if="lang ==='es' " width="35">
                <img src="../../assets/images/icons/en.png" class="xs-mr-5" v-else  width="30">
                <span class="caret"></span></btn>
              <template slot="dropdown">
                <li><a role="button" @click="changeLang('en')" class="decoration-none">
                  <img src="../../assets/images/icons/en.png" class="xs-mr-5" width="30">English
                </a></li>
                <li role="separator" class="divider"></li>
                <li @click="changeLang('es')">
                  <a role="button" class="decoration-none">
                    <img src="../../assets/images/icons/jp.png" class="xs-mr-5" width="35">Español
                  </a>
                </li>
              </template>
            </dropdown>
          </div>
          <!--<user-top-mobile></user-top-mobile>-->
          <home-user-top-mobile></home-user-top-mobile>
        </div>
    <div class="hidden-sm hidden-xs hidden-lg hidden-md border-radius-bottom login-box-mobile" style="z-index: 990;">
      <div>
        <div v-on:click="toggleLogin" style="margin: 0 10px;">
          <img v-if="!$auth.check()" src="https://static.phdvasia.com/mx/icons/inactive@2x.png" class=""
               width="25">
          <img src="https://static.phdvasia.com/mx/icons/active@2x.png" class="" width="25" v-else>
        </div>
        <div class="clearfix pb15" v-if="flag"></div>
        <div  v-if="flag">
          <a href="https://pizzahut.jp/ip/sign_in" v-if="!$auth.check()"><span class="p5">{{ $t("login_title") }}</span></a>
          <router-link to="/profile" v-else><span class="p5">{{$auth.user().name}}</span></router-link>
        </div>
        <div class="clearfix pt-10" v-if="flag">
          <router-link to="/register" v-if="!$auth.check()">{{ $t("login_register")}}</router-link>
          <button v-else v-on:click="$root.$emit('logout')">logout</button>
        </div>
      </div>
    </div>
    <h1 class="logo-home xs-shrink-0 screenreader-only xs-my-5" style="z-index: 990;">Pizza Hut</h1>
    <div class="pb-20 t16 links" >
      <a href="javascript: void(0)" v-on:click="scrollToElement('contact-us')" ><span class="text-black">{{$t('hut_contact')}}</span></a>
      <a href="javascript: void(0)" v-on:click="scrollToElement('opening-hours')"  >
        <span class="text-black">{{$t('hut_store', {store_name: selectedHutOutlet ? selectedHutOutlet.name : ''})}}</span>
      </a>
    </div>
    <div class="home-localise-content xs-mb-30 xs-pb-10 xs-z-200 new-disposition ">
      <home-localization-map></home-localization-map>
      <button v-if="selectedHutOutlet"
        v-on:click="changeOrderType"
        class="btn btn-success btn-menu">{{ $t('hut_see_menu', {store_name: selectedHutOutlet.name}) }}</button>
      <!--<div class="localisation-form xs-relative xs-mx-auto xs-z-400">
        <div style="box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 22px 0px;">
          <div class="xs-flex text-glitch-fix">
            <div data-synth="form-delivery" data-type="DELIVERY"
                 class="order-type-button xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-10 bold rounded-top no-outline xs-nowrap text-glitch-fix "
                 v-bind:class="isDelivery" role="button" style="margin-right: 2px; font-size: 16px;" tabindex="0"
                 v-on:click="clickActiveDelivery">
              <i class="icon-delivery xs-mr-5 xs-hide md-block"></i><span>{{$t('delivery')}}</span>
            </div>
            <div data-synth="form-collection" data-type="CARRYOUT"
                 class="order-type-button xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-10 bold rounded-top no-outline xs-nowrap text-glitch-fix"
                 v-bind:class="isCollection" role="button" style="font-size: 16px;" tabindex="0"
                 v-on:click="clickActiveCollection">
              <i class="icon-collection xs-mr-5 xs-hide md-block"></i><span>{{$t('collection')}}</span>
            </div>
          </div>
          <div class="localisation xs-fill-white xs-p-15 rounded-bottom">
            <button class="button button&#45;&#45;primary flex-1"  style="min-height: 40px" v-on:click="changeOrderType">
              <div class="flex w-full flex-col  t14" v>
                <span v-if="orderType ===$const.DELIVERY">{{$t('order_for_delivery')}}</span>
                <span v-else>{{$t('order_for_collection')}}</span>
              </div>
            </button>
          </div>
        </div>
      </div>-->
    </div>
    <div class="clearfix"></div>
  </div>
    </div>

  </div>
</template>
<script>

import {
  mapGetters,
  mapActions
} from 'vuex'

import UserTopMobile from '../partials/UserTopMobile'
import HomeUserTopMobile from '../home/HomeUserTopMobile'
import HomeLocalizationMap from '@/components/home/HomeLocalizationMap'

let active = 'xs-fill-white xs-text-red xs-relative xs-z-200 xs-py-15'
let inactive = 'xs-fill-gray-lightest xs-text-gray-medium xs-grayscale'

export default {
  components: {
    UserTopMobile,
    HomeUserTopMobile,
    HomeLocalizationMap
  },
  computed: {
    ...mapGetters({
      selectedHutOutletId: 'selectedHutOutletId',
      selectedHutOutlet: 'selectedHutOutlet'
    })
  },
  watch: {
    selectedHutOutletId: function (newValue, oldValue) {

    }
  },
  mounted () {
    this.$disposition.init(this)
    this.clickActiveCollection()
  },
  data () {
    return {
      lang: this.$session.get('lang'),
      flag: false,
      isDelivery: inactive,
      isCollection: active,
      orderType: this.isCollection === active ? this.$const.CARRYOUT : this.$const.DELIVERY
    }
  },
  methods: {
    ...mapActions({
      setSelectedOutlet: 'setSelectedOutlet'
    }),
    toggleLogin () {
      this.flag = !this.flag
    },
    changeLang (la) {
      this.lang = la
      this.$store.dispatch('setLang', la)
      this.$session.set('lang', la)
    },
    changeOrderType () {
      let vm = this

      // this.$session.set('orderType', this.orderType)
      this.setOutletById(vm, this.selectedHutOutlet.id, this.$const.CARRYOUT)
    },
    /**
     * Click on Delivery tab
     * */
    clickActiveDelivery () {
      this.isDelivery = active
      this.isCollection = inactive
      this.orderType = this.$const.DELIVERY
      this.$root.$emit('onChangeOrderType', this.orderType)
    },
    /**
     * Click on Collection tab
     * */
    clickActiveCollection () {
      this.isDelivery = inactive
      this.isCollection = active
      this.orderType = this.$const.CARRYOUT
      this.$root.$emit('onChangeOrderType', this.orderType)
    },
    scrollToElement (el) {
      let container = document.body.querySelector('#' + el)
      window.scrollTo(0, container.offsetTop)
    }
  }
}
</script>
