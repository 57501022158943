<template>
  <div>
    <home-top></home-top>
    <slot></slot>
    <!--<home-dinein></home-dinein>-->
    <home-footer></home-footer>
  </div>
</template>
<script>
import HomeTop from '../components/home/HomeTopLanding.vue'
import HomeFooter from '../components/home/HomeFooter.vue'

export default {
  mounted () {
  },
  components: {
    HomeTop,
    HomeFooter
  }
}
</script>
<style >
  body {
    padding-right: 0px;
    background: none;

  }
</style>
